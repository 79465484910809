<template>
	<div :class="'is-banner ' + (type == 1 ? 'mini-banner' : type == 2 ? 'vertical-banner' : 'square-banner')">
		<img src="@/assets/img/minzdrav_logo_small.png" alt="Минздрав" />
		<div class="banner-content">
			<div class="banner-text">НЕЗАВИСИМАЯ ОЦЕНКА КАЧЕСТВА <br />УСЛОВИЙ ОКАЗАНИЯ УСЛУГ <br />МЕДИЦИНСКИМИ ОРГАНИЗАЦИЯМИ</div>
			<div class="banner-btns">
				<a target="_blank" :href="'https://anketanok.minzdrav.gov.ru/' + url + '/' + id">Оценить</a>
			</div>
		</div>
	</div>
</template>

<script>
import { actions } from '@/store/store';

export default {
	name: 'GetBannerNok',
	data() {
		return {
			id: '0',
			mo: {},
			level: 'ogv',
			type: 0
		};
	},
	computed: {
		url: function () {
			if (this.level === 'ogv' || this.level === 'OGV' || !this.mo.regionId) {
				return 'ambulator';
			}
			if (this.mo.ambulator) {
				return `ambulator/${this.mo.regionId}`;
			}
			if (this.mo.stacionar) {
				return `stacionar/${this.mo.regionId}`;
			}
			if (this.mo.healthResort) {
				return `healthResort/${this.mo.regionId}`;
			}
			if (this.mo.psychiatric) {
				return `psychiatric/${this.mo.regionId}`;
			}
			return 'ambulator';
		}
	},
	methods: {
		...actions
	},
	mounted() {
		this.id = this.$route.params.id;
		this.type = this.$route.params.type;
		this.level = this.$route.params.level;
		if (this.level === 'mo' || this.level === 'MO') {
			if (this.id.length > 7) {
				this.getMObyId(this.id).then((res) => {
					this.mo = res.data;
				});
			} else {
				this.getMObyOldId(this.id).then((res) => {
					this.mo = res.data;
				});
			}
		} else {
			if (this.id.length < 7) {
				this.getOGVbyOldId(this.id).then((res) => {
					this.id = res.data.id;
				});
			}
		}
	}
};
</script>
<style scoped>
.is-banner {
	text-transform: uppercase;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flexbox;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.is-banner .banner-text {
	color: #414141;
	font-size: 10px;
	line-height: 1.5;
	font-weight: bold;
	font-family: 'Arial';
	text-align: center;
}
.is-banner .banner-btns {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flexbox;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	margin-top: 9px;
}
.is-banner a {
	color: #414141;
	text-decoration: none;
	padding: 4px;
	display: block;
	text-align: center;
	font-size: 11px;
	font-weight: bold;
	font-family: 'Arial';
	width: 87px;
	height: 23px;
	background-image: url('~@/assets/img/btn.png');
	cursor: pointer;
}

.mini-banner {
	width: 300px;
	height: 110px;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('~@/assets/img/bg1.png');
}
.mini-banner img {
	width: 85px;
	height: 85px;
	margin: 6px;
	display: block;
}
.mini-banner .banner-text {
	width: 200px;
	padding-top: 0px;
	margin-top: 7px;
	margin-left: -7px;
}
.mini-banner a {
	margin-left: 50px;
	margin-top: 3px;
}

.vertical-banner {
	width: 120px;
	height: 260px;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('~@/assets/img/bg2.png');
}
.vertical-banner img {
	width: 85px;
	height: 85px;
	margin-top: 21px;
}
.vertical-banner .banner-text {
	width: 100px;
	margin-top: 5px;
	line-height: 1.6;
}
.vertical-banner .banner-btns,
.square-banner .banner-btns {
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.vertical-banner .banner-btns {
	margin-top: 18px;
}
.vertical-banner .banner-btns a {
	margin-bottom: 10px;
}

.square-banner {
	width: 300px;
	height: 200px;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0px;
	background-image: url('~@/assets/img/bg3.png');
}
.square-banner img {
	width: 95px;
	height: 95px;
	margin: 6px;
}
.square-banner .banner-text {
	width: 270px;
	margin-top: 3px;
	font-size: 11px;
}
.square-banner .banner-btns a {
	margin-bottom: 10px;
}
</style>
